@import '../../../../scss/theme-bootstrap';

.hero-block {
  position: relative;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  border: none;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
  }
  &__content-over-media {
    z-index: 9;
    position: relative;
    &-wrapper {
      overflow: auto;
      .hero-block--no-media & {
        display: flex;
        flex-direction: column;
      }
      .playing-video & {
        display: none;
      }
    }
  }
  &__content-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 0 15px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__content-media {
    z-index: -1;
  }
  &__link-wrapper {
    padding: 10px 0;
    &.hero-block__mobile-link-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      z-index: 10;
    }
  }

  // Helpers
  .content-block__content {
    height: auto;
    flex: 0;
    display: block;
  }
  .content-block__line {
    display: inline-block;
  }
  p {
    margin: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .button {
    &--dark:hover {
      color: $color-white;
    }
    &--light:hover {
      color: $color-black;
    }
  }
  div.pc-hidden {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  .mantle-media-asset {
    height: auto;
    line-height: 0;
  }

  // Layout Options
  &--flip-layout {
    display: flex;
    flex-direction: column-reverse;
  }
  &--flip-layout-mobile {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint($landscape-up) {
      flex-direction: column;
    }
  }
  &--flip-layout-desktop {
    display: flex;
    flex-direction: column;
    @include breakpoint($landscape-up) {
      flex-direction: column-reverse;
    }
  }
  &--force-full-width {
    width: 100vw;
    position: relative;
    #{$ldirection}: 50%;
    #{$rdirection}: 50%;
    margin-#{$ldirection}: -50vw;
    margin-#{$rdirection}: -50vw;
    max-width: none;
  }
  div.hero-block--mobile-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  div.hero-block--pc-hidden {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
